<template>
  <StartImage parent="projects"></StartImage>
  <section class="inner-projects text-start section-margin">
    <div class="container">
      <h5>
        <span>Our Projects</span>
      </h5>
      <h3 class="section-title-margin">
        Feel free to browse all of our projects and more.
      </h3>
      <div class="row">
        <div class="col-sm-12 col-md-6 col-lg-4 col-xl-3">
          <div class="card box-shadow mb-4">
            <img src="../assets/project1.jpg" loading="lazy" class="card-img" />
          </div>
        </div>

        <div class="col-sm-12 col-md-6 col-lg-4 col-xl-3">
          <div class="card box-shadow mb-4">
            <img src="../assets/project3.jpg" loading="lazy" class="card-img" />
          </div>
        </div>

        <div class="col-sm-12 col-md-6 col-lg-4 col-xl-3">
          <div class="card box-shadow mb-4">
            <img src="../assets/project5.jpg" loading="lazy" class="card-img" />
          </div>
        </div>

        <div class="col-sm-12 col-md-6 col-lg-4 col-xl-3">
          <div class="card box-shadow mb-4">
            <img src="../assets/project6.jpg" loading="lazy" class="card-img" />
          </div>
        </div>

        <div class="col-sm-12 col-md-6 col-lg-4 col-xl-3">
          <div class="card box-shadow mb-4">
            <img src="../assets/project7.jpg" loading="lazy" class="card-img" />
          </div>
        </div>

        <div class="col-sm-12 col-md-6 col-lg-4 col-xl-3">
          <div class="card box-shadow mb-4">
            <img src="../assets/project8.jpg" loading="lazy" class="card-img" />
          </div>
        </div>

        <div class="col-sm-12 col-md-6 col-lg-4 col-xl-3">
          <div class="card box-shadow mb-4">
            <img src="../assets/project9.jpg" loading="lazy" class="card-img" />
          </div>
        </div>

        <div class="col-sm-12 col-md-6 col-lg-4 col-xl-3">
          <div class="card box-shadow mb-4">
            <img
              src="../assets/project10.jpg"
              loading="lazy"
              class="card-img"
            />
          </div>
        </div>

        <div class="col-sm-12 col-md-6 col-lg-4 col-xl-3">
          <div class="card box-shadow mb-4">
            <img
              src="../assets/project11.jpg"
              loading="lazy"
              class="card-img"
            />
          </div>
        </div>

        <div class="col-sm-12 col-md-6 col-lg-4 col-xl-3">
          <div class="card box-shadow mb-4">
            <img
              src="../assets/project12.jpg"
              loading="lazy"
              class="card-img"
            />
          </div>
        </div>

        <div class="col-sm-12 col-md-6 col-lg-4 col-xl-3">
          <div class="card box-shadow mb-4">
            <img
              src="../assets/project12.jpg"
              loading="lazy"
              class="card-img"
            />
          </div>
        </div>

        <div class="col-sm-12 col-md-6 col-lg-4 col-xl-3">
          <div class="card box-shadow mb-4">
            <img
              src="../assets/project13.jpg"
              loading="lazy"
              class="card-img"
            />
          </div>
        </div>

        <div class="col-sm-12 col-md-6 col-lg-4 col-xl-3">
          <div class="card box-shadow mb-4">
            <img
              src="../assets/project14.jpg"
              loading="lazy"
              class="card-img"
            />
          </div>
        </div>

        <div class="col-sm-12 col-md-6 col-lg-4 col-xl-3">
          <div class="card box-shadow mb-4">
            <img
              src="../assets/project15.jpg"
              loading="lazy"
              class="card-img"
            />
          </div>
        </div>

        <div class="col-sm-12 col-md-6 col-lg-4 col-xl-3">
          <div class="card box-shadow mb-4">
            <img
              src="../assets/project16.jpg"
              loading="lazy"
              class="card-img"
            />
          </div>
        </div>

        <div class="col-sm-12 col-md-6 col-lg-4 col-xl-3">
          <div class="card box-shadow mb-4">
            <img
              src="../assets/project20.jpg"
              loading="lazy"
              class="card-img"
            />
          </div>
        </div>

        <div class="col-sm-12 col-md-6 col-lg-4 col-xl-3">
          <div class="card box-shadow mb-4">
            <img
              src="../assets/project17.jpg"
              loading="lazy"
              class="card-img"
            />
          </div>
        </div>

        <div class="col-sm-12 col-md-6 col-lg-4 col-xl-3">
          <div class="card box-shadow mb-4">
            <img
              src="../assets/project21.jpg"
              loading="lazy"
              class="card-img"
            />
          </div>
        </div>

        <div class="col-sm-12 col-md-6 col-lg-4 col-xl-3">
          <div class="card box-shadow mb-4">
            <img src="../assets/project2.jpg" loading="lazy" class="card-img" />
          </div>
        </div>

        <div class="col-sm-12 col-md-6 col-lg-4 col-xl-3">
          <div class="card box-shadow mb-4">
            <img src="../assets/project4.jpg" loading="lazy" class="card-img" />
          </div>
        </div>

        <div class="col-sm-12 col-md-6 col-lg-4 col-xl-3">
          <div class="card box-shadow mb-4">
            <img
              src="../assets/project18.jpg"
              loading="lazy"
              class="card-img"
            />
          </div>
        </div>

        <div class="col-sm-12 col-md-6 col-lg-4 col-xl-3">
          <div class="card box-shadow mb-4">
            <img
              src="../assets/project19.jpg"
              loading="lazy"
              class="card-img"
            />
          </div>
        </div>
      </div>

      <div class="text-center mb-5">
        <router-link to="/contact-us" class="btn btn-beig mt-4"
          >Contact Us</router-link
        >
      </div>
    </div>
  </section>
</template>

<script>
import StartImage from "../components/StartImage.vue";

export default {
  components: {
    StartImage,
  },
};
</script>

<style>
.inner-projects h4 {
  margin-bottom: 20px;
}

.inner-projects h5 span {
  border-bottom: 3px solid #ffe3c2;
  padding-bottom: 5px;
  padding-right: 5px;
  padding-left: 5px;
}

.inner-projects h5 {
  margin-bottom: 25px;
}
</style>
